<template>
  <div class="appDescriptionsItem">
    <div class="appDescriptionsItem-label">{{ label }}</div>
    <div class="appDescriptionsItem-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appDescriptionsItem {
  border-bottom: 1px dashed #ecebef;
  padding-bottom: 10px;
  // height: 24px;
  padding-top: 20px;
  line-height: 24px;
  display: flex;
  align-items: stretch;

  &-label {
    color: color.$subText;
    font-size: 16px;
    width: 140px;
  }

  &-content {
    color: color.$baseText;
    width: 0;
    flex: 1;
    font-size: 16px;
  }
}
</style>
