<template>
  <div class="appOrder-th">
    <span> {{ label }}</span>
    <span>
      <slot name="default"></slot>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: String,
  },
  data() {
    return {};
  },
  mounted() {
    // 获取数据
    // this.getData();
  },
  methods: {
    /**
     * 设置span
     */
    setSpan(span) {
      if (span.columnIndex == 0) {
        return [1, this.span];
      } else if (span.columnIndex == this.span) {
        return [1, 1];
      } else {
        return [0, 0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder-th {
  color: color.$tableText;
  font-size: 14px;
  @include fn.separate(12px, color.$info);
}
</style>
