const utils = {
  /**
   * 检查格式
   */
  check(value, msg, pattern) {
    // 如果正则公式存在
    if (pattern) {
      // 获取正则
      const reg = new RegExp(pattern);

      // 判断正则，不符合则抛出异常
      if (!reg.test(value)) throw new Error(msg);
    } else {
      // 判断布尔值，为否则抛出异常
      if (!value) throw new Error(msg);
    }
  },

  /**
   * 校验身份证是否正确
   * @param {*} sId  身份证号
   * @param {*} ShowMsg
   *
   */
  valiteIdCard(sId, ShowMsg = '') {
    let aCity = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外',
    };
    let iSum = 0;

    if (!/^\d{17}(\d|x)$/i.test(sId)) return new Error('您输入的' + ShowMsg + '身份证长度或格式错误,请检查！');
    sId = sId.replace(/x$/i, 'a');
    if (aCity[parseInt(sId.substr(0, 2))] == null) return new Error('你输入的' + ShowMsg + '身份证地区非法,请检查！');
    let sBirthday = sId.substr(6, 4) + '-' + Number(sId.substr(10, 2)) + '-' + Number(sId.substr(12, 2));
    var d = new Date(sBirthday.replace(/-/g, '/'));
    if (sBirthday != d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()) return new Error('您输入的' + ShowMsg + '身份证上的出生日期非法,请检查！');
    for (var i = 17; i >= 0; i--) iSum += (Math.pow(2, i) % 11) * parseInt(sId.charAt(17 - i), 11);
    if (iSum % 11 != 1) return new Error('您输入的' + ShowMsg + '身份证号非法,请检查！');
    return undefined;
  },

  /**
   * 确认密码
   */
  valiteConfirmpass(newPass, confirmPass) {
    if (newPass != confirmPass) {
      return new Error('确认密码与密码不同，请确认');
    }
    return undefined;
  },
  /**
   * 当前日
   */
  nowDate() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month >= 1 && month <= 9) {
      month = '0' + month;
    }
    if (day >= 0 && day <= 9) {
      day = '0' + day;
    }
    var currentdate = year + '-' + month + '-' + day;
    return currentdate;
  },
};
export default utils;
