<template>
  <div
    class="appTable-operation-label"
    :class="{
      'appTable-operation-label_disabled': disabled,
    }"
    @click.stop="labelClick">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  emits: ['click'],
  components: {},
  computed: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 处理点击事件
     */
    labelClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appTable-operation-label {
  color: color.$tableText;
  cursor: pointer;
  position: relative;
  user-select: none;
  font-size: 14px;
  transition: 500ms;
  @include fn.separate;

  &:hover {
    color: color.$base;
  }

  &_disabled {
    color: color.$borderHover;
    cursor: not-allowed;

    &:hover {
      color: color.$borderHover;
    }
  }
}
</style>
