<template>
  <!-- <teleport to="body"> -->
  <transition name="fade">
    <div ref="appDialog2" class="appDialog2" v-if="dialogVisible">
      <div class="appDialog2-shadow"></div>
      <div
        class="appDialog2-content"
        :style="{
          width: computedWidth,
        }">
        <div class="appDialog2-header">
          <div class="appDialog2-title">
            <div class="appDialog2-title-spot"></div>

            {{ title }}
          </div>
          <div v-show="isClose" class="appDialog2-close" @click="close()">
            <app-icon size="21px" name="close" />
          </div>
        </div>
        <div class="appDialog2-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
  <!-- </teleport> -->
  <!-- <el-dialog
    custom-class="appDialog2"
    destroy-on-close
    v-model="dialogVisible"
    :title="title"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
    @closed="closed"
  >
    <slot></slot>
  </el-dialog> -->
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    width: {
      default: 400,
      type: Number,
    },
    isClose: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    computedWidth() {
      if (!isNaN(parseFloat(this.width))) {
        return this.width + 'px';
      } else {
        return this.width;
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      id: '',
    };
  },
  // mounted() {
  //   document.addEventListener("keyup", this.keyup);
  // },
  // unmounted() {
  //   document.removeEventListener("keyup", this.keyup);
  // },
  methods: {
    async open() {
      this.dialogVisible = true;
      // this.id = String(new Date().getTime());
      // const storage = JSON.parse(sessionStorage.getItem("dialogId")) || [];
      // storage.push(this.id);
      // sessionStorage.setItem("dialogId", JSON.stringify(storage));
      await this.$nextTick();
    },
    close() {
      this.dialogVisible = false;
      this.closed();
    },
    closed() {
      Object.assign(this.$parent.$data, this.$parent.$options.data.call(this.$parent));
    },
    keyup(e) {
      const storage = JSON.parse(sessionStorage.getItem('dialogId')) || [];
      if (storage.at(-1) == this.id && e.code === 'Escape') {
        this.dialogVisible = false;
        storage.pop();
        sessionStorage.setItem('dialogId', JSON.stringify(storage));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appDialog2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: rgba($color: #000000, $alpha: 0.4);

  &-content {
    overflow: hidden;
    width: 400px;
    border-radius: 4px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    background-color: #fff;

    animation-name: content-show;
    animation-duration: 0.4s;
  }

  &-header {
    border-bottom: 1px solid color.$border;
    margin-right: 0;
    padding: 0 24px;
    height: 65px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    //color: color.$base;
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-weight: bold;
    height: 65px;
    line-height: 64px;
    position: relative;
    margin-left: 16px;
    //&::after {
    //  content: "";
    //  display: block;
    //  height: 4px;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //  position: absolute;
    //  background: color.$base;
    //}

    &-spot {
      width: 8px;
      height: 8px;
      background: color.$base;
      border-radius: 50%;
      position: absolute;
      left: -14px;
      top: 28px;
    }
  }

  &-close {
    color: color.$base;
    cursor: pointer;
  }

  ::v-deep(.appPage) {
    padding: 0 20px;
  }

  ::v-deep(.appTable-footer) {
    padding-top: 0;
    padding-bottom: 0;
    height: 100px;
  }
}

@keyframes content-show {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// // 弹框
// .appDialog2 {
//   border-radius: 10px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   height: fit-content;

//   .appPage {
//     padding: 0 20px;
//   }

//   .appTable-footer {
//     padding-top: 0;
//     padding-bottom: 0;
//     height: 100px;
//   }

//   .el-dialog__header {
//     border-bottom: 1px solid color.$border;
//     margin-right: 0;
//     padding: 0 24px;
//     height: 65px;
//     display: flex;
//     align-items: center;
//   }

//   .el-dialog__title {
//     font-size: 18px;
//     font-family: Source Han Sans CN;
//     font-weight: bold;
//     color: color.$base;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     position: relative;
//     padding: 0 10px;

//     &::after {
//       content: "";
//       display: block;
//       height: 4px;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       position: absolute;
//       background: #6663ff;
//     }
//   }

//   .el-dialog__body {
//     padding: 0;
//   }
//   .el-dialog__close {
//     border-radius: 50%;
//     border: 2px solid color.$base;
//     color: color.$base;
//     width: 24px;
//     height: 24px;

//     svg {
//       width: 20px;
//       height: 20px;
//     }
//   }
// }
</style>
