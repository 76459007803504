<template>
  <app-dialog2 ref="dialog" title="选择父级菜单" :width="1070">
    <app-status ref="page" class="page">
      <app-status ref="appTable" class="appTable" @error="getData()">
        <el-form class="appFilterbar" ref="filterForm" :model="filterData" @submit.prevent="filterSubmit()">
          <app-slide>
            <div class="appFilterbar-list">
              <el-form-item prop="refundSn" class="appFilterbar-item">
                <el-input v-model="filterData.name" placeholder="请输入景区名称"></el-input>
              </el-form-item>
            </div>
          </app-slide>
          <div class="appFilterbar-buttongourp">
            <el-button @click="filterReset()" type="primary" plain class="appFilterbar-button">重置</el-button>
            <el-button native-type="submit" type="primary" class="appFilterbar-button">查询</el-button>
          </div>
        </el-form>
        <el-table :data="table.list" class="appTable-data" ref="appTable-data" stripe>
          <el-table-column prop="id" label="序号" min-width="60" />
          <el-table-column prop="code" label="景区编码" min-width="240" />
          <el-table-column prop="name" label="景区名称" min-width="240" />

          <el-table-column label="操作" width="80" fixed="right">
            <template #default="scope">
              <app-table-operation>
                <app-table-operation-label @click="elementSelect(scope.row)"> 选择 </app-table-operation-label>
              </app-table-operation>
            </template>
          </el-table-column>
        </el-table>
        <div class="appTable-footer">
          <el-pagination background layout="prev, pager, next" :page-count="table.total" @current-change="pageChange" />
        </div>
      </app-status>
      <div class="appPage-footer">
        <div class="appPage-buttongourp">
          <el-button @click="$refs.dialog.close()" type="primary" class="appPage-buttongourp-button"> 取消 </el-button>
        </div>
      </div>
    </app-status>
  </app-dialog2>
</template>

<script>
export default {
  emits: ['select'],
  components: {},
  data() {
    return {
      table: {
        list: [],
        page: 1,
        total: 1,
        allCheck: false,
        selected: [],
      },
      filterData: {
        name: '',
      },
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    async open() {
      // 打开弹窗
      await this.$refs.dialog.open();

      // 获取数据
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      try {
        // 设置状态
        this.$refs.appTable.status('init');

        // 获取数据
        const result = await this.$request.get('/distributor/shop/scenic/search', {
          params: {
            query: {
              name: this.filterData.name,
            },
            page: this.table.page,
          },
        });

        // 设置数据
        this.table.list = result.data.data;
        this.table.total = result.data.last_page;

        // 设置状态
        this.$refs.appTable.status('default');
      } catch (e) {
        // 设置状态
        this.$refs.appTable.status('error', e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menuTree-select {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;

  &-status {
    display: flex;
    flex-direction: column;
  }
}
</style>
