<template>
  <div class="app">
    <!-- 左侧 -->
    <div class="app-leftview" :class="{ 'app-leftview_show': menuSlide }">
      <div class="app-leftview-content">
        <app-menu class="app-menu" :menuname="index"></app-menu>
        <app-menu-mini class="app-menu-mini" :menuname="index"></app-menu-mini>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="app-rightview">
      <app-header-tag class="app-nav" @selected="selected"></app-header-tag>
      <app-header class="app-header"></app-header>
      <app-content class="app-content"></app-content>
    </div>
  </div>
  <activation ref="activation" />
  <remaining ref="remaining" />

</template>

<script>
import { mapState } from 'vuex';
import appMenu from '@/components/app-menu';
import appMenuMini from '@/components/app-menu-mini';
import appHeader from '@/components/app-header';
import appContent from '@/components/app-content';
import appHeaderTag from '@/components/app-header-tag';
import activation from '@/components/activation/activation.vue'
import remaining from '@/components/activation/remaining.vue'

export default {
  name: 'index',
  components: {
    appMenu,
    appMenuMini,
    appHeader,
    appContent,
    appHeaderTag,
    activation,
    remaining,
  },

  computed: {
    ...mapState('app', ['menuSlide']),
  },
  data() {
    return {
      index: '',
      loginData: {},
      is_expired: '',
      expire_left_day: '',
    };
  },
  async mounted() {
    //打开弹窗
    await this.loginActivate();
    if (this.loginData.admin_type != 0 && this.is_expired == true) {
      await this.$refs.activation.open();
    } else if (this.expire_left_day < 30 && this.expire_left_day > 0) {
      console.log('expire_left_day', this.expire_left_day);
      await this.$refs.remaining.open(this.expire_left_day);
    }
  },
  methods: {
    selected(e) {
      this.index = e;
    },
    async loginActivate() {
      const result = await this.$request.get('/auth/user-profile',);
      this.loginData = result.data;
      this.is_expired = result.data.is_expired;
      this.expire_left_day = result.data.expire_left_day;
    }
  },
};
</script>

<style lang="scss">
.app {
  height: 100vh;
  width: 100vw;
  display: flex;

  &-leftview {
    width: 240px;
    height: 100%;
    transition: all 500ms;
    position: relative;
    //overflow: hidden;
    // z-index: 21;
    z-index: 10;

    &_show {
      width: 240px;
    }

    &-content {
      width: calc(240px + 240px);
      display: flex;
      transform: translateX(-240px);
      transition: 500ms transform;
      height: 100%;
    }

    &_show &-content {
      transform: translateX(0%);
    }
  }

  &-rightview {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 11;
  }

  &-menu {
    height: 100%;
    user-select: none;
  }

  &-nav {
    height: 66px;
  }

  &-header {
    height: 55px;
  }

  &-content {
    flex: 1;
    height: 0;
  }
}
</style>
