<template>
  <div
    class="iconfont"
    :class="[type + '-' + name]"
    :style="{
      'font-size': _size,
      'line-height': _size,
      width: _size,
      height: _size,
    }"></div>
</template>

<script>
export default {
  computed: {
    _size() {
      if (/^\d+rpx$/.test(this.size)) {
        return this.size.replace(/^(\d+)rpx/g, `calc($1 / 7.5 * 1vw)`);
      } else {
        return this.size;
      }
    },
  },
  props: {
    type: {
      type: String,
      default: 'app',
    },
    name: {
      type: String,
    },
    size: {
      type: String,
      default: '16px',
    },
  },
};
</script>

<style scoped lang="scss">
.iconfont {
  display: block;
  font-size: 16px;
}
</style>
