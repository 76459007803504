<template>
  <div class="appOrder-label" @click.capture.stop="onClick">
    <span class="appOrder-label-title">{{ label }}</span>
    <span
      class="appOrder-label-content"
      :class="{
        'appOrder-label-content_button': type == 'button',
        'appOrder-label-content_button_disabled': disabled,
      }"
      ><slot name="default"></slot
    ></span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder-label {
  &-title {
    color: color.$inputText;
  }

  &-content {
    &_button {
      color: color.$base;
      cursor: pointer;

      &_disabled {
        color: color.$borderHover;
        cursor: not-allowed;

        &:hover {
          color: color.$borderHover;
        }
      }
    }
  }
}
</style>
