<template>
  <el-date-picker
    ref="a"
    v-model="inner_modelValue"
    :type="type"
    unlink-panels
    range-separator="-"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :placeholder="placeholder"
    :shortcuts="shortcuts" />
</template>

<script>
export default {
  components: {},
  props: {
    modelValue: [String, Object],
    startPlaceholder: String,
    endPlaceholder: String,
    placeholder: String,
  },
  watch: {
    type: {
      immediate: true,
      handler(type) {
        if (type == 'date') {
          this.inner_modelValue = '';
        } else if (type == 'daterange') {
          this.inner_modelValue = [];
        }
      },
    },
    modelValue() {
      // this.inner_modelValue = "";
    },
    inner_modelValue(date) {
      console.log(date);
      if (date === null) {
        if (this.type === 'date') {
          this.inner_modelValue = '';
          this.$emit('update:modelValue', '');
        }
        if (this.type === 'daterange') {
          this.inner_modelValue = [];
        }
      } else {
        if (this.type === 'daterange') {
          const [min = '', max = ''] = date;
          this.$emit('update:modelValue', { min, max });
        }
      }
    },
  },
  data() {
    return {
      validateEvent: true,
      inner_modelValue: null,
      shortcuts: [
        {
          text: '上周',
          value: () => {
            const start = this.$moment().subtract(1, 'week').startOf('week');
            const end = this.$moment().subtract(1, 'week').endOf('week');

            return [start, end];
          },
        },
        {
          text: '上月',
          value: () => {
            const start = this.$moment().subtract(1, 'months').startOf('month');
            const end = this.$moment().subtract(1, 'months').endOf('month');

            return [start, end];
          },
        },
      ],
    };
  },
  mounted() {
    console.log(this.$refs);
  },
  methods: {
    resetField() {
      console.log('a');
    },
  },
};
</script>

<style scoped lang="scss"></style>
