// 默认路由
const defaultRouter = {
  path: 'home',
  name: 'agent.home',
  component: () => import('@/views/agent/home/index.vue'),
  meta: {
    title: '首页',
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: 'tourists',
      name: 'agent.tourists',
      component: () => import('@/views/agent/tourists/index.vue'),
      meta: {
        // title: '游客画像',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productManage',
      name: 'agent.productManage',
      component: () => import('@/views/agent/productManage/index.vue'),
      meta: {
        title: '产品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'analysisChart',
      name: 'agent.analysisChart',
      component: () => import('@/views/agent/analysisChart/index.vue'),
      meta: {
        title: '数据分析',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },

    {
      path: 'presaleTicketBatch',
      name: 'agent.presaleTicketBatch',
      component: () => import('@/views/agent/presaleTicketBatch/index.vue'),
      meta: {
        title: '预售票批次',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'productManageSpecialty',
      name: 'agent.productManageSpecialty',
      component: () => import('@/views/agent/productManageSpecialty/index.vue'),
      meta: {
        title: '文创产品',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'logisticsTemplate',
      name: 'agent.logisticsTemplate',
      component: () => import('@/views/agent/logisticsTemplate/index.vue'),
      meta: {
        title: '物流模板',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productManageCombination',
      name: 'agent.productManageCombination',
      component: () => import('@/views/agent/productManageCombination/index.vue'),
      meta: {
        title: '组合商品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'productManageLine',
      name: 'agent.productManageLine',
      component: () => import('@/views/agent/productManageLine/index.vue'),
      meta: {
        title: '线路产品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productManageRestaurant',
      name: 'agent.productManageRestaurant',
      component: () => import('@/views/agent/productManageRestaurant/index.vue'),
      meta: {
        title: '餐饮产品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'fleetManagement',
      name: 'agent.fleetManagement',
      component: () => import('@/views/agent/fleetManagement/index.vue'),
      meta: {
        title: '车队管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productManageFleet',
      name: 'agent.productManageFleet',
      component: () => import('@/views/agent/productManageFleet/index.vue'),
      meta: {
        title: '车队产品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productManageHotel',
      name: 'agent.productManageHotel',
      component: () => import('@/views/agent/productManageHotel/index.vue'),
      meta: {
        title: '酒店产品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productGroup',
      name: 'agent.productGroup',
      component: () => import('@/views/agent/productGroup/index.vue'),
      meta: {
        title: '产品组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'ticketDocking',
      name: 'agent.ticketDocking',
      component: () => import('@/views/agent/ticketDocking/index.vue'),
      meta: {
        title: '票务系统对接',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'distributorDocking',
      name: 'agent.distributorDocking',
      component: () => import('@/views/agent/distributorDocking/index.vue'),
      meta: {
        title: '分销商对接配置',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'inventoryManage',
      name: 'agent.inventoryManage',
      component: () => import('@/views/agent/inventoryManage/index.vue'),
      meta: {
        title: '库存管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'inventoryCalendar',
      name: 'agent.inventoryCalendar',
      component: () => import('@/views/agent/inventoryCalendar/index.vue'),
      meta: {
        title: '库存日历',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'saleChannel',
      name: 'agent.saleChannel',
      component: () => import('@/views/agent/saleChannel/index.vue'),
      meta: {
        title: '分销商渠道',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'saleCodePrice',
      name: 'agent.saleCodePrice',
      component: () => import('@/views/agent/saleCodePrice/index.vue'),
      meta: {
        title: '销码价格列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'saleCodeCalendar',
      name: 'agent.saleCodeCalendar',
      component: () => import('@/views/agent/saleCodeCalendar/index.vue'),
      meta: {
        title: '渠道价格日历',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'refundRule',
      name: 'agent.refundRule',
      component: () => import('@/views/agent/refundRule/index.vue'),
      meta: {
        title: '退票规则管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'blackWhite',
      name: 'agent.blackWhite',
      component: () => import('@/views/agent/blackWhite/index.vue'),
      meta: {
        title: '我的黑白名单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'whiteGroup',
      name: 'agent.whiteGroup',
      component: () => import('@/views/agent/whiteGroup/index.vue'),
      meta: {
        title: '我的白名单组',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'wechatBinding',
      name: 'agent.wechatBinding',
      component: () => import('@/views/agent/wechatBinding/index.vue'),
      meta: {
        title: '绑定微信',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'receivedMessage',
      name: 'agent.receivedMessage',
      component: () => import('@/views/agent/receivedMessage/index.vue'),
      meta: {
        title: '已收消息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'alreadyMessage',
      name: 'agent.alreadyMessage',
      component: () => import('@/views/agent/alreadyMessage/index.vue'),
      meta: {
        title: '已发消息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'msgTemplate',
      name: 'agent.msgTemplate',
      component: () => import('@/views/agent/msgTemplate/index.vue'),
      meta: {
        title: '消息组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'user',
      name: 'agent.user',
      component: () => import('@/views/agent/user/index.vue'),
      meta: {
        title: '用户列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'invoiceApply',
      name: 'agent.invoiceApply',
      component: () => import('@/views/agent/invoiceApply/index.vue'),
      meta: {
        title: '发票审核',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'creditAccount',
      name: 'agent.creditAccount',
      component: () => import('@/views/agent/creditAccount/index.vue'),
      meta: {
        title: '供应商授信账户管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'distributorCreditAccount',
      name: 'agent.distributorCreditAccount',
      component: () => import('@/views/agent/distributorCreditAccount/index.vue'),
      meta: {
        title: '分销商授信账户管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'creditAccountRecord',
      name: 'agent.creditAccountRecord',
      component: () => import('@/views/agent/creditAccountRecord/index.vue'),
      meta: {
        title: '供应商授信账户收支',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'distributorCreditAccountRecord',
      name: 'agent.distributorCreditAccountRecord',
      component: () => import('@/views/agent/distributorCreditAccountRecord/index.vue'),
      meta: {
        title: '分销商授信账户收支',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'transactionRecords',
      name: 'agent.transactionRecords',
      component: () => import('@/views/agent/transactionRecords/index.vue'),
      meta: {
        title: '交易记录查询',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'youcaiAccountRecord',
      name: 'agent.youcaiAccountRecord',
      component: () => import('@/views/agent/youcaiAccountRecord/index.vue'),
      meta: {
        title: '账户收支明细',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'soldOrder',
      name: 'agent.soldOrder',
      component: () => import('@/views/agent/soldOrder/index.vue'),
      meta: {
        title: '分销订单管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    //distributorSoldOrder
    {
      path: 'distributorSoldOrder',
      name: 'agent.distributorSoldOrder',
      component: () => import('@/views/agent/distributorSoldOrder/index.vue'),
      meta: {
        title: '采购订单管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'checkPendingRefundTicket',
      name: 'agent.checkPendingRefundTicket',
      component: () => import('@/views/agent/checkPendingRefundTicket/index.vue'),
      meta: {
        title: '待审核退票列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'refundTicket',
      name: 'agent.refundTicket',
      component: () => import('@/views/agent/refundTicket/index.vue'),
      meta: {
        title: '退票列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'info',
      name: 'agent.info',
      component: () => import('@/views/agent/info/index.vue'),
      meta: {
        title: '个人信息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'smsAccount',
      name: 'agent.smsAccount',
      component: () => import('@/views/agent/smsAccount/index.vue'),
      meta: {
        title: '短信账户',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'orderSmsTemplate',
      name: 'agent.orderSmsTemplate',
      component: () => import('@/views/agent/orderSmsTemplate/index.vue'),
      meta: {
        title: '短信模版管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'youcaiAccount',
      name: 'agent.youcaiAccount',
      component: () => import('@/views/agent/youcaiAccount/index.vue'),
      meta: {
        title: `账户管理`,
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'roleGroup',
      name: 'agent.roleGroup',
      component: () => import('@/views/agent/roleGroup/index.vue'),
      meta: {
        title: '角色组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'corpInfo',
      name: 'agent.corpInfo',
      component: () => import('@/views/agent/corpInfo/index.vue'),
      meta: {
        title: '企业信息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'paymentConfig',
      name: 'agent.paymentConfig',
      component: () => import('@/views/agent/paymentConfig/index.vue'),
      meta: {
        title: '支付配置',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'journal',
      name: 'agent.journal',
      component: () => import('@/views/agent/journal/index.vue'),
      meta: {
        title: '操作日志',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowSalesOrder',
      name: 'agent.windowSalesOrder',
      component: () => import('@/views/agent/windowSalesOrder/index.vue'),
      meta: {
        title: '窗口售票订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowMembershipCard',
      name: 'agent.windowMembershipCard',
      component: () => import('@/views/agent/windowMembershipCard/index.vue'),
      meta: {
        title: '会员卡管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowUserMembershipCard',
      name: 'agent.windowUserMembershipCard',
      component: () => import('@/views/agent/windowUserMembershipCard/index.vue'),
      meta: {
        title: '用户会员卡列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowExchangeVIP',
      name: 'agent.windowExchangeVIP',
      component: () => import('@/views/agent/windowExchangeVIP/index.vue'),
      meta: {
        title: '会员卡兑换',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowRedeemTickets',
      name: 'agent.windowRedeemTickets',
      component: () => import('@/views/agent/windowRedeemTickets/index'),
      meta: {
        title: '会员兑换门票',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowVipWriteOff',
      name: 'agent.windowVipWriteOff',
      component: () => import('@/views/agent/windowVipWriteOff/index'),
      meta: {
        title: '会员卡核销列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    // {
    //   path: 'windowReport',
    //   name: 'agent.windowReport',
    //   component: () => import('@/views/agent/windowReport/index'),
    //   meta: {
    //     title: "景区表报",
    //     auth: {
    //       required: true,
    //       header: false,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: 'windowBusinessReport',
      name: 'agent.windowBusinessReport',
      component: () => import('@/views/agent/windowBusinessReport/index'),
      meta: {
        title: '剧场营业报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'seatNumReport',
      name: 'agent.seatNumReport',
      component: () => import('@/views/agent/seatNumReport/index'),
      meta: {
        title: '剧场座位报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterChannelReport',
      name: 'agent.theaterChannelReport',
      component: () => import('@/views/agent/theaterChannelReport/index'),
      meta: {
        title: '剧场渠道报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterChannelReport',
      name: 'agent.theaterChannelReport',
      component: () => import('@/views/agent/theaterChannelReport/index'),
      meta: {
        title: '剧场渠道报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterSettlementReport',
      name: 'agent.theaterSettlementReport',
      component: () => import('@/views/agent/theaterSettlementReport/index'),
      meta: {
        title: '剧场结算报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterWriteOffReport',
      name: 'agent.theaterWriteOffReport',
      component: () => import('@/views/agent/theaterWriteOffReport/index'),
      meta: {
        title: '剧场核销报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowTicketCheckReport',
      name: 'agent.windowTicketCheckReport',
      component: () => import('@/views/agent/windowTicketCheckReport/index'),
      meta: {
        title: '检票报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'presaleCodeReport',
      name: 'agent.presaleCodeReport',
      component: () => import('@/views/agent/presaleCodeReport/index'),
      meta: {
        title: '预售票报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'gateInspector',
      name: 'agent.gateInspector',
      component: () => import('@/views/agent/gateInspector/index'),
      meta: {
        title: '闸机核验员管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    // {
    //   path: 'windowReportForm',
    //   name: 'agent.windowReportForm',
    //   component: () => import('@/views/agent/windowReportForm/index'),
    //   meta: {
    //     title: "营业报表模板导出",
    //     auth: {
    //       required: true,
    //       header: true,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: 'windowReportTemplate',
      name: 'agent.windowReportTemplate',
      component: () => import('@/views/agent/windowReportTemplate/index'),
      meta: {
        title: '报表模板列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'conductorReport',
      name: 'agent.conductorReport',
      component: () => import('@/views/agent/windowReportTemplate/conductorReport'),
      meta: {
        title: '报表', // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'channelReport',
      name: 'agent.channelReport',
      component: () => import('@/views/agent/windowReportTemplate/channelReport'),
      meta: {
        title: '报表', // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'report',
      name: 'agent.report',
      component: () => import('@/views/agent/windowReportTemplate/report'),
      meta: {
        title: '报表', // 自定义营业报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowWriteOff',
      name: 'agent.windowWriteOff',
      component: () => import('@/views/agent/windowWriteOff/index'),
      meta: {
        title: '核销记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowTicketExchangeTicket',
      name: 'agent.windowTicketExchangeTicket',
      component: () => import('@/views/agent/windowTicketExchangeTicket/index'),
      meta: {
        title: '核销码兑换门票',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowExchangeRecord',
      name: 'agent.windowExchangeRecord',
      component: () => import('@/views/agent/windowExchangeRecord/index.vue'),
      meta: {
        title: '会员卡兑换记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowVipExchangeTicket',
      name: 'agent.windowVipExchangeTicket',
      component: () => import('@/views/agent/windowVipExchangeTicket/index.vue'),
      meta: {
        title: '会员兑换门票记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowSalesRefundHistory',
      name: 'agent.windowSalesRefundHistory',
      component: () => import('@/views/agent/windowSalesRefundHistory/index.vue'),
      meta: {
        title: '退票历史',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    // {
    //   path: 'windowSalesRefund',
    //   name: 'agent.windowSalesRefund',
    //   component: () => import('@/views/agent/windowSalesRefund/index.vue'),
    //   meta: {
    //     auth: {
    //       required: true,
    //       header: true,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: 'windowSales',
      name: 'agent.windowSales',
      component: () => import('@/views/agent/windowSales/index.vue'),
      meta: {
        title: '窗口售票',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'windowSalesGroup',
      name: 'agent.windowSalesGroup',
      component: () => import('@/views/agent/windowSalesGroup/index.vue'),
      meta: {
        title: '窗口售票(团票)',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'theaterSales',
      name: 'agent.theaterSales',
      component: () => import('@/views/agent/theaterSales/index.vue'),
      meta: {
        title: '剧场售票',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'windowFace',
      name: 'agent.windowFace',
      component: () => import('@/views/agent/windowFace/index.vue'),
      meta: {
        title: '人脸机管理',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'windowFaceManage',
      name: 'agent.windowFaceManage',
      component: () => import('@/views/agent/windowFaceManage/index.vue'),
      meta: {
        title: '人脸管理',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'windowTicketingGroup',
      name: 'agent.windowTicketingGroup',
      component: () => import('@/views/agent/windowTicketingGroup/index.vue'),
      meta: {
        title: '售票组权限管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowVipGroup',
      name: 'agent.windowVipGroup',
      component: () => import('@/views/agent/windowVipGroup/index.vue'),
      meta: {
        title: '会员卡分组',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowGate',
      name: 'agent.windowGate',
      component: () => import('@/views/agent/windowGate/index.vue'),
      meta: {
        title: '闸机管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowHardware',
      name: 'agent.windowHardware',
      component: () => import('@/views/agent/windowHardware/index.vue'),
      meta: {
        title: '硬件管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowPrinter',
      name: 'agent.windowPrinter',
      component: () => import('@/views/agent/windowPrinter/index.vue'),
      meta: {
        title: '打印机管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowTicketChecking',
      name: 'agent.windowTicketChecking',
      component: () => import('@/views/agent/windowTicketChecking/index.vue'),
      meta: {
        title: '检票组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappCoupon',
      name: 'agent.uniappCoupon',
      component: () => import('@/views/agent/uniappCoupon/index.vue'),
      meta: {
        title: '优惠券管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'specialtyOrder',
      name: 'agent.specialtyOrder',
      component: () => import('@/views/agent/specialtyOrder/index.vue'),
      meta: {
        title: '特产订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterZone',
      name: 'agent.theaterZone',
      component: () => import('@/views/agent/theaterZone/index.vue'),
      meta: {
        title: '剧场区域',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterSessions',
      name: 'agent.theaterSessions',
      component: () => import('@/views/agent/theaterSessions/index.vue'),
      meta: {
        title: '剧场场次',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterGroup',
      name: 'agent.theaterGroup',
      component: () => import('@/views/agent/theaterGroup/index.vue'),
      meta: {
        title: '剧场区域组',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterSeat',
      name: 'agent.theaterSeat',
      component: () => import('@/views/agent/theaterSeat/index.vue'),
      meta: {
        title: '剧场座位',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'seatImg',
      name: 'agent.seatImg',
      component: () => import('@/views/agent/seatImg/index.vue'),
      meta: {
        title: '座位图',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'seatOrder',
      name: 'agent.seatOrder',
      component: () => import('@/views/agent/seatOrder/index.vue'),
      meta: {
        title: '座位订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'seatLock',
      name: 'agent.seatLock',
      component: () => import('@/views/agent/seatLock/index.vue'),
      meta: {
        title: '剧场座位锁定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'theaterSeatImg',
      name: 'agent.theaterSeatImg',
      component: () => import('@/views/agent/theaterSeatImg/index.vue'),
      meta: {
        title: '剧场座位图',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiCardManage',
      name: 'agent.skiCardManage',
      component: () => import('@/views/agent/skiCardManage/index.vue'),
      meta: {
        title: '开卡管理',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },

    {
      path: 'skiCoachCardManage',
      name: 'agent.skiCoachCardManage',
      component: () => import('@/views/agent/skiCoachCardManage/index.vue'),
      meta: {
        title: '教练卡管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiConsumptionCardManage',
      name: 'agent.skiConsumptionCardManage',
      component: () => import('@/views/agent/skiConsumptionCardManage/index.vue'),
      meta: {
        title: '消费卡管理',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'skiLease',
      name: 'agent.skiLease',
      component: () => import('@/views/agent/skiLease/index.vue'),
      meta: {
        title: '雪具租赁',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'skiCourse',
      name: 'agent.skiCourse',
      component: () => import('@/views/agent/skiCourse/index.vue'),
      meta: {
        title: '课程销售',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'skiCardRefund',
      name: 'agent.skiCardRefund',
      component: () => import('@/views/agent/skiCardRefund/index.vue'),
      meta: {
        title: '退卡管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiProductReturn',
      name: 'agent.skiProductReturn',
      component: () => import('@/views/agent/skiProductReturn/index.vue'),
      meta: {
        title: '租赁物品退换',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiSoldOrderLease',
      name: 'agent.skiSoldOrderLease',
      component: () => import('@/views/agent/skiSoldOrderLease/index.vue'),
      meta: {
        title: '租赁订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiSoldOrderCourse',
      name: 'agent.skiSoldOrderCourse',
      component: () => import('@/views/agent/skiSoldOrderCourse/index.vue'),
      meta: {
        title: '课程订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiSettlementLog',
      name: 'agent.skiSettlementLog',
      component: () => import('@/views/agent/skiSettlementLog/index.vue'),
      meta: {
        title: '结算卡日志',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiExpenseLog',
      name: 'agent.skiExpenseLog',
      component: () => import('@/views/agent/skiExpenseLog/index.vue'),
      meta: {
        title: '消费卡日志',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiExchange',
      name: 'agent.skiExchange',
      component: () => import('@/views/agent/skiExchange/index.vue'),
      meta: {
        title: '雪具兑换',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiProductTimeout',
      name: 'agent.skiProductTimeout',
      component: () => import('@/views/agent/skiProductTimeout/index.vue'),
      meta: {
        title: '超时补费',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiCoachCardStatus',
      name: 'agent.skiCoachCardStatus',
      component: () => import('@/views/agent/skiCoachCardStatus/index.vue'),
      meta: {
        title: '教练状态',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiCoachOverTime',
      name: 'agent.skiCoachOverTime',
      component: () => import('@/views/agent/skiCoachOverTime/index.vue'),
      meta: {
        title: '教练结时',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiTicketTimeout',
      name: 'agent.skiTicketTimeout',
      component: () => import('@/views/agent/skiTicketTimeout/index.vue'),
      meta: {
        title: '门票超时补费',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiSoldOrderTicket',
      name: 'agent.skiSoldOrderTicket',
      component: () => import('@/views/agent/skiSoldOrderTicket/index.vue'),
      meta: {
        title: '雪场门票订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'skiAreaProductExchange',
      name: 'agent.skiAreaProductExchange',
      component: () => import('@/views/agent/skiAreaProductExchange/index.vue'),
      meta: {
        title: '雪具兑换',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'guideCommission',
      name: 'agent.guideCommission',
      component: () => import('@/views/agent/skiReportForms/guideCommission.vue'),
      meta: {
        title: '导游反佣报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'coachCommission',
      name: 'agent.coachCommission',
      component: () => import('@/views/agent/skiReportForms/coachCommission.vue'),
      meta: {
        title: '教练反佣报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'ticketForm',
      name: 'agent.ticketForm',
      component: () => import('@/views/agent/skiReportForms/ticketForm.vue'),
      meta: {
        title: '滑雪门票报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'leaseForm',
      name: 'agent.leaseForm',
      component: () => import('@/views/agent/skiReportForms/leaseForm.vue'),
      meta: {
        title: '租赁报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'trainForm',
      name: 'agent.trainForm',
      component: () => import('@/views/agent/skiReportForms/trainForm.vue'),
      meta: {
        title: '教练课报表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'replaceSeatRecord',
      name: 'agent.replaceSeatRecord',
      component: () => import('@/views/agent/replaceSeatRecord/index.vue'),
      meta: {
        title: '换座记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'travelAgency',
      name: 'agent.travelAgency',
      component: () => import('@/views/agent/travelAgency/index.vue'),
      meta: {
        title: '线下旅行社管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'passport',
      name: 'agent.passport',
      component: () => import('@/views/agent/passport/index.vue'),
      meta: {
        title: '护照管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'ticketExchangePassport',
      name: 'agent.ticketExchangePassport',
      component: () => import('@/views/agent/ticketExchangePassport/index.vue'),
      meta: {
        title: '护照兑换',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'foodOrder',
      name: 'agent.foodOrder',
      component: () => import('@/views/agent/foodOrder/index.vue'),
      meta: {
        title: '餐饮订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'goodsOrder',
      name: 'agent.goodsOrder',
      component: () => import('@/views/agent/goodsOrder/index.vue'),
      meta: {
        title: '商品订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'commissionRecord',
      name: 'agent.commissionRecord',
      component: () => import('@/views/agent/commissionRecord/index.vue'),
      meta: {
        title: '佣金记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'guideManage',
      name: 'agent.guideManage',
      component: () => import('@/views/agent/guideManage/index.vue'),
      meta: {
        title: '导游管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'recordLog',
      name: 'agent.recordLog',
      component: () => import('@/views/agent/recordLog/index.vue'),
      meta: {
        title: '出入记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'freeIntoGardenLog',
      name: 'agent.freeIntoGardenLog',
      component: () => import('@/views/agent/freeIntoGardenLog/index.vue'),
      meta: {
        title: '免费入园记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
  ],
};
