<template>
    <dialog2 ref="dialog" :width="380">
        <!--    <app-dialog2 ref="dialog" :width="380">-->
        <app-status ref="page" class="appDetail">
            <div>
                <img src="@/assets/image/activation.png" alt="" class="appDetail-logo" />
            </div>
            <div class="title">尊敬的用户您好</div>
            <div class='content'>
                您的管理端使用权限已到期，请输入激活码进行权限激活。
            </div>
            <div>
                <el-input v-model="formData.code" placeholder="请输入请输入您的激活码" class="appDetail-form-input"
                    clearable></el-input>
                <div class="telephoneNumber">请联系管理员获取激活码</div>
            </div>
            <div class="appDetail-buttongourp">
                <el-button @click="formSubmit()" type="primary" class="appDetail-buttongourp-button"> 立即激活
                </el-button>
                <el-button @click="logout" type="primary" class="appDetail-buttongourp-button"> 退出登录
                </el-button>
            </div>
            <div class="record" @click="$refs.check.open()">查看历史记录</div>
        </app-status>
        <!--    </app-dialog2>-->
    </dialog2>
    <check ref="check" />
</template>
<script>
import check from '@/components/app-header-tag/check.vue';
import dialog2 from './app-dialog2.vue';
import { mapState, mapActions } from 'vuex';
export default {
    emits: ['confirm'],
    components: {
        check,
        dialog2,
    },
    computed: {
        ...mapState('user', ['userInfo']),
    },
    data() {
        return {
            formData: {
                code: '',
                // description: '',
                // sort: '',
            },
            formRules: {
                code: [
                    {
                        required: true,
                        message: '请填写组名称',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        /**
         * 打开弹框
         */
        async open() {
            // 打开弹框
            await this.$refs.dialog.open();
        },
        ...mapActions('user', ['logout']),
        /**
         * 表单提交
         */
        async formSubmit() {
            try {
                // 获取参数
                const { code } = this.formData;
                // 发送请求
                await this.$request.post('/common/account/activation', {
                    code,
                });
                // 弹出提示
                this.$message.success('添加成功');

                // 弹出成功
                this.$emit('confirm');

                // 关闭弹窗
                this.$refs.dialog.close();

                //刷新页面
                window.location.reload();
            } catch (e) {
                // 提示错误
                this.$message.error(e.message);
            } finally {
                // 关闭加载
                // loading.close();
            }
            // 表单验证
            // this.$refs.detailForm.validate(async (valid) => {
            //     // 验证通过
            //     if (valid) {
            //         // 加载状态
            //         const loading = this.$loading();

            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.appDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        margin-top: 16px;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
    }

    .content {
        margin-top: 8px;
        font-size: 12px;
        color: #333333;
        line-height: 17px;
        text-align: center;
    }

    .appDetail-form-input {
        margin-top: 24px;
        width: 260px;
    }

    .telephoneNumber {
        margin-top: 6px;
        font-size: 10px;
        color: #2281FF;
        line-height: 14px;
        text-align: left;
    }

    .appDetail-buttongourp {
        margin-top: 24px;

        .appDetail-buttongourp-button {
            width: 260px;
            height: 30px;
            font-weight: 600;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 17px;
            margin-bottom: 10px;
            margin-left: 0;
        }
    }

    .record {
        // margin-top: 8px;
        font-weight: 400;
        font-size: 10px;
        color: #AAAAAA;
        line-height: 14px;

        &:hover {
            color: #2281FF;
            cursor: pointer;
        }
    }
}
</style>
