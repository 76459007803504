<template>
  <div class="appDescriptions">
    <div class="appDescriptions-header" v-if="title">
      <div>{{ title }}</div>
      <slot name="btn"></slot>
    </div>
    <div class="appDescriptions-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    column: {
      default: 2,
      type: Number,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appDescriptions {
  // padding: 10px 60px;

  &-header {
    color: color.$base;
    border-bottom: 1px dashed color.$base;
    padding-bottom: 10px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    ::v-deep(.appDescriptionsItem) {
      // width: calc(100% / v-bind(column));
      flex: 1 0 calc(100% / v-bind(column));
    }
  }
}
</style>
