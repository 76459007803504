<template>
  <div class="app-menu-primary">

    <div class="app-menu-primary-section" :class="{ 'app-menu-primary-section_active2': active }" @click="$emit('toNav', menu)">
      <app-icon class="app-menu-primary-icon"  style="margin-left: 20px;" :style="active?'color:#fff':'color: #3b7ded'" :name="menu.icon" />
      <div class="app-menu-primary-title2" :class="{ 'app-menu-primary-title2_active': active }">
        {{ menu.name }}
      </div>
    </div>
  </div>
</template>

<script>
// import appMenuSecond from './app-menu-second';
export default {
  emits: ['toNav'],
  components: {
    // appMenuSecond,
  },
  props: {
    menu: Object,
  },
  computed: {
    // active() {
    //   console.log(this.menu);
    //   console.log(this.$route.name)
    //   return this.find(this.menu).includes(this.$route.name);
    // },
  },
  watch: {
    menu: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive();
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive();
      },
    },
  },
  data() {
    return {
      // secondShow: false,
      active: false,
    };
  },
  methods: {
    /**
     * 设置激活
     */
    setActive() {
      this.active = this.find(this.menu).includes(this.$route.name);
      if (this.active) this.secondShow = true;
    },

    /**
     * 查找当前路由是否存在于menu中
     */
    find(menu, result = []) {
      if (menu.children?.length) {
        menu.children.forEach((item) => {
          this.find(item, result);
        });
      } else {
        result.push(menu.path);
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-menu-primary {
  display: flex;
  justify-content: center;
  &-section {
    width: 218px;
    height: 59px;
    border-radius: 3px;
    border: 1px solid #DCDFE6;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    //border-bottom: 1px solid #212533;

    &-icon {
      position: absolute;
      right: 13px;
      font-size: 14px !important;
    }
    // 激活
    &_active2 {
      background: color.$base;
    }
    &_active2::after {
      width: 4px;
      height: 100%;
      //background: color.$base;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:hover::before {
      pointer-events: none;
      opacity: 0.1;
      content: '';
      background: #3b7ded;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }



  &-title2 {
    height: 56px;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #333333;
    text-decoration: none;
    &_active {
      color: #fff;
    }
  }
}
</style>
