// 默认路由
const defaultRouter = {
  path: 'home',
  name: 'distributor.home',
  component: () => import('@/views/distributor/home/index.vue'),
  meta: {
    title: '首页',
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: 'tourists',
      name: 'distributor.tourists',
      component: () => import('@/views/distributor/tourists/index.vue'),
      meta: {
        title: '游客画像',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'wechatBinding',
      name: 'distributor.wechatBinding',
      component: () => import('@/views/distributor/wechatBinding/index.vue'),
      meta: {
        title: '绑定微信',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'info',
      name: 'distributor.info',
      component: () => import('@/views/distributor/info/index.vue'),
      meta: {
        title: '个人信息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'contact',
      name: 'distributor.contact',
      component: () => import('@/views/distributor/contact/index.vue'),
      meta: {
        title: '常用联系人',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'user',
      name: 'distributor.user',
      component: () => import('@/views/distributor/user/index.vue'),
      meta: {
        title: '用户列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'receivedMessage',
      name: 'distributor.receivedMessage',
      component: () => import('@/views/distributor/receivedMessage/index.vue'),
      meta: {
        title: '已收消息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'batchReserve',
      name: 'distributor.batchReserve',
      component: () => import('@/views/distributor/batchReserve/index.vue'),
      meta: {
        title: '批量下单预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'batchReserveOrder',
      name: 'distributor.batchReserveOrder',
      component: () => import('@/views/distributor/batchReserveOrder/index.vue'),
      meta: {
        title: '批量下单订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveHotel',
      name: 'distributor.reserveHotel',
      component: () => import('@/views/distributor/reserveHotel/index.vue'),
      meta: {
        title: '酒店预订',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveRestaurant',
      name: 'distributor.reserveRestaurant',
      component: () => import('@/views/distributor/reserveRestaurant/index.vue'),
      meta: {
        title: '餐饮预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveLine',
      name: 'distributor.reserveLine',
      component: () => import('@/views/distributor/reserveLine/index.vue'),
      meta: {
        title: '线路预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveFleet',
      name: 'distributor.reserveFleet',
      component: () => import('@/views/distributor/reserveFleet/index.vue'),
      meta: {
        title: '车队预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveCombination',
      name: 'distributor.reserveCombination',
      component: () => import('@/views/distributor/reserveCombination/index.vue'),
      meta: {
        title: '组合商品预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveLine',
      name: 'distributor.reserveLine',
      component: () => import('@/views/distributor/reserveLine/index.vue'),
      meta: {
        title: '线路预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveSpecialty',
      name: 'distributor.reserveSpecialty',
      component: () => import('@/views/distributor/reserveSpecialty/index.vue'),
      meta: {
        title: '特产预定',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserve',
      name: 'distributor.reserve',
      component: () => import('@/views/distributor/reserve/index.vue'),
      meta: {
        title: '景区门票预订',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'hotelOrder',
      name: 'distributor.uniappPaymentOrder',
      component: () => import('@/views/distributor/uniappPaymentOrder/index.vue'),
      meta: {
        title: '任意付订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'hotelOrder',
      name: 'distributor.hotelOrder',
      component: () => import('@/views/distributor/hotelOrder/index.vue'),
      meta: {
        title: '酒店订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'orderHotelList',
      name: 'distributor.orderHotelList',
      component: () => import('@/views/distributor/uniappOrderHotelList'),
      meta: {
        title: '酒店订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'fleetOrder',
      name: 'distributor.fleetOrder',
      component: () => import('@/views/distributor/fleetOrder/index.vue'),
      meta: {
        title: '车队订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'restaurantOrder',
      name: 'distributor.restaurantOrder',
      component: () => import('@/views/distributor/restaurantOrder/index.vue'),
      meta: {
        title: '餐饮订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'specialtyOrder',
      name: 'distributor.specialtyOrder',
      component: () => import('@/views/distributor/specialtyOrder/index.vue'),
      meta: {
        title: '特产订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'lineOrder',
      name: 'distributor.lineOrder',
      component: () => import('@/views/distributor/lineOrder/index.vue'),
      meta: {
        title: '线路订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'combinationOrder',
      name: 'distributor.combinationOrder',
      component: () => import('@/views/distributor/combinationOrder/index.vue'),
      meta: {
        title: '组合商品订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveOrder',
      name: 'distributor.reserveOrder',
      component: () => import('@/views/distributor/reserveOrder/index.vue'),
      meta: {
        title: '景区门票订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'groupReserve',
      name: 'distributor.groupReserve',
      component: () => import('@/views/distributor/groupReserve/index.vue'),
      meta: {
        title: '团票下单预订',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'groupReserveOrder',
      name: 'distributor.groupReserveOrder',
      component: () => import('@/views/distributor/groupReserveOrder/index.vue'),
      meta: {
        title: '团票下单订单',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'balanceWarning',
      name: 'distributor.balanceWarning',
      component: () => import('@/views/distributor/balanceWarning/index.vue'),
      meta: {
        title: '余额预警',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'creditWarning',
      name: 'distributor.creditWarning',
      component: () => import('@/views/distributor/creditWarning/index.vue'),
      meta: {
        title: '授信预警',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'invoiceApply',
      name: 'distributor.invoiceApply',
      component: () => import('@/views/distributor/invoiceApply/index.vue'),
      meta: {
        title: '发票申请',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'youcaiAccountWithdraw',
      name: 'distributor.youcaiAccountWithdraw',
      component: () => import('@/views/distributor/youcaiAccountWithdraw/index.vue'),
      meta: {
        title: '账户提现申请',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'creditAccount',
      name: 'distributor.creditAccount',
      component: () => import('@/views/distributor/creditAccount/index.vue'),
      meta: {
        title: '授信账户',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'creditAccountRecord',
      name: 'distributor.creditAccountRecord',
      component: () => import('@/views/distributor/creditAccountRecord/index.vue'),
      meta: {
        title: '授信账户收支',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'transactionRecords',
      name: 'distributor.transactionRecords',
      component: () => import('@/views/distributor/transactionRecords/index.vue'),
      meta: {
        title: '交易记录查询',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'refund',
      name: 'distributor.refund',
      component: () => import('@/views/distributor/refund/index.vue'),
      meta: {
        title: '退款记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'roleGroup',
      name: 'distributor.roleGroup',
      component: () => import('@/views/distributor/roleGroup/index.vue'),
      meta: {
        title: '角色组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'corpInfo',
      name: 'distributor.corpInfo',
      component: () => import('@/views/distributor/corpInfo/index.vue'),
      meta: {
        title: '企业信息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'shopping',
      name: 'distributor.shopping',
      component: () => import('@/views/distributor/shopping/index.vue'),
      meta: {
        title: '景区',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'shoppingHotel',
      name: 'distributor.shoppingHotel',
      component: () => import('@/views/distributor/shoppingHotel/index.vue'),
      meta: {
        title: '酒店',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'shoppingCart',
      name: 'distributor.shoppingCart',
      component: () => import('@/views/distributor/shoppingCart/index.vue'),
      meta: {
        title: '购物车',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'uniappShopInfo',
      name: 'distributor.uniappShopInfo',
      component: () => import('@/views/distributor/uniappShopInfo/index.vue'),
      meta: {
        title: '商城信息',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'uniappAdressCode',
      name: 'distributor.uniappAdressCode',
      component: () => import('@/views/distributor/uniappAdressCode/index.vue'),
      meta: {
        title: '地址标签管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappTrim',
      name: 'distributor.uniappTrim',
      component: () => import('@/views/distributor/uniappTrim/index.vue'),
      meta: {
        title: '模板管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappTrimEdit',
      name: 'distributor.uniappTrimEdit',
      component: () => import('@/views/distributor/uniappTrim/trim.vue'),
      meta: {
        title: '装修页面',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'uniappGoodsCategory',
      name: 'distributor.uniappGoodsCategory',
      component: () => import('@/views/distributor/uniappGoodsCategory/index.vue'),
      meta: {
        title: '商品分类',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappConsultingClassification',
      name: 'distributor.uniappConsultingClassification',
      component: () => import('@/views/distributor/uniappConsultingClassification/index.vue'),
      meta: {
        title: '咨询分类',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappConsultingManagement',
      name: 'distributor.uniappConsultingManagement',
      component: () => import('@/views/distributor/uniappConsultingManagement/index.vue'),
      meta: {
        title: '游记攻略',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappProductList',
      name: 'distributor.uniappProductList',
      component: () => import('@/views/distributor/uniappProductList/index.vue'),
      meta: {
        title: '门票列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    //酒店管理
    {
      path: 'uniappHotelList',
      name: 'distributor.uniappHotelList',
      component: () => import('@/views/distributor/uniappHotelList/index.vue'),
      meta: {
        title: '酒店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappOrderList',
      name: 'distributor.uniappOrderList',
      component: () => import('@/views/distributor/uniappOrderList/index.vue'),
      meta: {
        title: '订单列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappOrderLineList',
      name: 'distributor.uniappOrderLineList',
      component: () => import('@/views/distributor/uniappOrderLineList/index.vue'),
      meta: {
        title: '订单列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappRecommendedScenic',
      name: 'distributor.uniappRecommendedScenic',
      component: () => import('@/views/distributor/uniappRecommendedScenic/index.vue'),
      meta: {
        title: '推荐景区',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappRecommendArticle',
      name: 'distributor.uniappRecommendArticle',
      component: () => import('@/views/distributor/uniappRecommendArticle/index.vue'),
      meta: {
        title: '热门文章',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    //活动管理
    {
      path: 'uniappActivityList',
      name: 'distributor.uniappActivityList',
      component: () => import('@/views/distributor/uniappActivityList/index.vue'),
      meta: {
        title: '抽奖活动',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappUserList',
      name: 'distributor.uniappUserList',
      component: () => import('@/views/distributor/uniappUserList/index.vue'),
      meta: {
        title: '用户列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappAlipayExamine',
      name: 'distributor.uniappAlipayExamine',
      component: () => import('@/views/distributor/uniappAlipayExamine/index.vue'),
      meta: {
        title: '支付宝小程序审核',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappRichText',
      name: 'distributor.uniappRichText',
      component: () => import('@/views/distributor/uniappRichText/index.vue'),
      meta: {
        title: '富文本',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappPageLink',
      name: 'distributor.uniappPageLink',
      component: () => import('@/views/distributor/uniappPageLink/index.vue'),
      meta: {
        title: '页面链接',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    //线路管理
    {
      path: 'uniappLineList',
      name: 'distributor.uniappLineList',
      component: () => import('@/views/distributor/uniappLine'),
      meta: {
        title: '线路管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    //线路管理
    {
      path: 'uniappFleet',
      name: 'distributor.uniappFleet',
      component: () => import('@/views/distributor/uniappFleet'),
      meta: {
        title: '车队管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappSpecialty',
      name: 'distributor.uniappSpecialty',
      component: () => import('@/views/distributor/uniappSpecialty'),
      meta: {
        title: '特产管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'alipayReflow',
      name: 'distributor.alipayReflow',
      component: () => import('@/views/distributor/alipayReflow'),
      meta: {
        title: '支付宝回流',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'windowReportTemplate',
      name: 'distributor.windowReportTemplate',
      component: () => import('@/views/distributor/windowReportTemplate'),
      meta: {
        title: '报表管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'conductorReport',
      name: 'distributor.conductorReport',
      component: () => import('@/views/distributor/windowReportTemplate/conductorReport'),
      meta: {
        title: '报表', // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'channelReport',
      name: 'distributor.channelReport',
      component: () => import('@/views/distributor/windowReportTemplate/channelReport'),
      meta: {
        title: '报表', // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'report',
      name: 'distributor.report',
      component: () => import('@/views/distributor/windowReportTemplate/report'),
      meta: {
        title: '报表', // 自定义营业报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappUserDistributorExamine',
      name: 'distributor.uniappUserDistributorExamine',
      component: () => import('@/views/distributor/uniappUserDistributorExamine/index'),
      meta: {
        title: '分销员审核',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappUserDistributorManage',
      name: 'distributor.uniappUserDistributorManage',
      component: () => import('@/views/distributor/uniappUserDistributorManage/index'),
      meta: {
        title: '分销员管理',
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: 'uniappUserDistributorLog',
      name: 'distributor.uniappUserDistributorLog',
      component: () => import('@/views/distributor/uniappUserDistributorLog/index'),
      meta: {
        title: '分销员绑定日志',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'uniappUserDistributorList',
      name: 'distributor.uniappUserDistributorList',
      component: () => import('@/views/distributor/uniappUserDistributorList/index'),
      meta: {
        title: '分销员列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
  ],
};
