<template>
  <app-dialog2 ref="dialog" title="激活记录" :width="1100">
    <app-status class="appPage" ref="page">
      <el-form class="appFilterbar" ref="filterForm" :model="filterData" @submit.prevent="filterSubmit()">
        <el-form-item class="appFilterbar-item">
          <el-input v-model="filterData.code" placeholder="请输入激活码"></el-input>
        </el-form-item>
        <el-form-item prop="play_date" class="appFilterbar-item">
          <el-date-picker v-model="filterData.play_date" type="daterange" value-format="YYYY-MM-DD" unlink-panels
            range-separator="-" start-placeholder="激活开始" end-placeholder="激活结束" :shortcuts="filterOptions.datetime" />
        </el-form-item>
        <div class="appFilterbar-buttongourp">
          <el-button @click="filterReset()" type="primary" plain class="appFilterbar-button">重置</el-button>
          <el-button native-type="submit" type="primary" class="appFilterbar-button">查询</el-button>
        </div>
      </el-form>

      <app-status ref="appTable" class="appTable" @error="getData()">
        <el-table :data="table.list" class="appTable-data" ref="appTable-data" stripe>
          <el-table-column prop="id" label="ID" width="80" />
          <el-table-column prop="code" label="激活码" min-width="180" />
          <el-table-column prop="corp_expiry_date" label="到期时间" min-width="200" />
          <el-table-column prop="activity_day" label="有效天数" min-width="180" />
          <!-- <el-table-column label="操作" width="80" fixed="right">
                        <template #default="scope">
                            <app-table-operation>
                                <app-table-operation-label @click="elementSelect(scope.row)"> 选择
                                </app-table-operation-label>
                            </app-table-operation>
                        </template>
</el-table-column> -->
        </el-table>
        <div class="appTable-footer">
          <el-pagination background layout="prev, pager, next" :page-count="table.total" @current-change="pageChange" />
        </div>
      </app-status>
    </app-status>
  </app-dialog2>
</template>

<script>
export default {
  emits: ['select'],
  components: {},
  data() {
    return {
      filterData: {
        code: '',
        play_date: [],
      },
      table: {
        list: [],
        page: 1,
        total: 1,
        allCheck: false,
        selected: [],
        admin_type: '',
      },
      filterOptions: {
        datetime: [
          {
            text: '上周',
            value: () => {
              const start = this.$moment().subtract(1, 'week').startOf('week');
              const end = this.$moment().subtract(1, 'week').endOf('week');

              return [start, end];
            },
          },
          {
            text: '上月',
            value: () => {
              const start = this.$moment().subtract(1, 'months').startOf('month');
              const end = this.$moment().subtract(1, 'months').endOf('month');

              return [start, end];
            },
          },
        ],
      }
    };
  },
  methods: {
    /**
     * 打开弹框
     */
    async open() {
      // 打开弹框
      await this.$refs.dialog.open();

      // 获取数据
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      try {
        // 设置状态
        this.$refs.page.status('init');

        // 获取数据
        const result = await this.$request.get('/common/account/activation-log', {
          params: {
            query: {
              code: this.filterData.code,
              'activity_date': {
                min: this.filterData.play_date?.[0],
                max: this.filterData.play_date?.[1],
              },
            },
            page: this.table.page,
          },
        });
        // 设置数据
        this.table.list = result.data.data;
        this.table.total = result.data.last_page;
        // 设置状态
        this.$refs.page.status('default');
      } catch (e) {
        // 设置状态
        this.$refs.page.status('error', e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
