<template>
  <!-- 支付失败 -->
  <app-dialog2 ref="dialog" :title="title" :width="600">
    <el-result :icon="type" :title="title" :sub-title="subTitle">
      <template #extra>
        <el-button type="primary" @click="cancel()">{{ cancelText }}</el-button>
        <el-button type="primary" @click="confirm()">{{ confirmText }}</el-button>
      </template>
    </el-result>
  </app-dialog2>
</template>

<script>
export default {
  emits: ['confirm', 'cancel'],
  components: {},
  data() {
    return {
      type: 'success',
      title: '',
      subTitle: '',
      confirmText: '确认',
      cancelText: '取消',
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    open({ type = 'success', title = '', subTitle = '', confirmText = '确认', cancelText = '取消' }) {
      this.type = type;
      this.title = title;
      this.subTitle = subTitle;
      this.confirmText = confirmText;
      this.cancelText = cancelText;

      this.$refs.dialog.open();
    },

    /**
     * 确认
     */
    confirm() {
      this.$refs.dialog.close();
      this.$emit('confirm');
    },

    /**
     * 取消
     */
    cancel() {
      this.$refs.dialog.close();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder-th {
  color: color.$tableText;
  font-size: 14px;
  @include fn.separate(12px, color.$info);
}
</style>
